@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Montserrat+Alternates&display=swap");
:root {
  --bg-main: #131722;
  --ligth-bg: #24baef;
  --main-color: #fff;
  --border-color: #a8a8a83d;
}
body {
  font-family: "Montserrat Alternates", sans-serif !important;
  background-color: #131722;
  color: var(--main-color);
}
a,
a:hover {
  text-decoration: none;
  color: #fff;
}
.brdr {
  height: 1px;
  background-color: var(--border-color);
}
input {
  background-color: transparent !important;
  color: #fff !important;
}
.person img {
  height: 250px;
}
/* Movies conponent  */
.page-link {
  cursor: pointer;
}
.f-container {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.loader {
  width: 38px;
  height: 38px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-link {
  cursor: pointer;
}
